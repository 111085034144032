import { Chip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import {
  DateRange,
  PickersShortcutsItem,
  PickersShortcutsProps,
  StaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ReactElement, useMemo, useRef, useState } from 'react';
import { FormattedDateTimeRange, useIntl } from 'react-intl';

dayjs.extend(quarterOfYear);

export type SelectPeriodForStatisticsProps = {
  start: Date | Dayjs;
  end: Date | Dayjs;
  onChange: (start: dayjs.Dayjs | null, end: dayjs.Dayjs | null) => void;
  shouldDisableDate?: (day: Dayjs, position: 'start' | 'end') => boolean;
};

const CustomRangeShortcuts = (
  props: PickersShortcutsProps<DateRange<Dayjs>>
) => {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        maxWidth: 595,
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <Grid container spacing={1} padding={2}>
        {resolvedItems.map((item) => (
          <Grid item key={item.label}>
            <Chip {...item} />
          </Grid>
        ))}
      </Grid>
      <Divider />
    </Box>
  );
};

const SelectPeriodForStatistics = ({
  start,
  end,
  onChange,
  shouldDisableDate,
}: SelectPeriodForStatisticsProps): ReactElement => {
  const [showCalendar, setShowCalendar] = useState(false);
  const anchorEl = useRef<HTMLElement | null>(null);
  const { formatMessage } = useIntl();

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = useMemo(
    () => [
      {
        label: formatMessage({ id: 'button.thisWeek' }),
        getValue: () => {
          const today = dayjs();
          return [today.startOf('week'), today.endOf('week')];
        },
      },
      {
        label: formatMessage({ id: 'button.lastWeek' }),
        getValue: () => {
          const today = dayjs();
          const prevWeek = today.subtract(7, 'day');
          return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
      },
      {
        label: formatMessage({ id: 'button.thisMonth' }),
        getValue: () => {
          const today = dayjs();
          return [today.startOf('month'), today.endOf('month')];
        },
      },
      {
        label: formatMessage({ id: 'button.lastMonth' }),
        getValue: () => {
          const today = dayjs();
          const startOfLastMonth = today.startOf('month').subtract(1, 'day');
          return [
            startOfLastMonth.startOf('month'),
            startOfLastMonth.endOf('month'),
          ];
        },
      },
      {
        label: formatMessage({ id: 'button.xQuarter' }, { quarter: 1 }),
        getValue: () => {
          const today = dayjs();
          const quarter = today.startOf('year').quarter(1);
          return [quarter, quarter.quarter(2).subtract(1, 'day')];
        },
      },
      {
        label: formatMessage({ id: 'button.xQuarter' }, { quarter: 2 }),
        getValue: () => {
          const today = dayjs();
          const quarter = today.startOf('year').quarter(2);
          return [quarter, quarter.quarter(3).subtract(1, 'day')];
        },
      },
      {
        label: formatMessage({ id: 'button.xQuarter' }, { quarter: 3 }),
        getValue: () => {
          const today = dayjs();
          const quarter = today.startOf('year').quarter(3);
          return [quarter, quarter.quarter(4).subtract(1, 'day')];
        },
      },
      {
        label: formatMessage({ id: 'button.xQuarter' }, { quarter: 4 }),
        getValue: () => {
          const today = dayjs();
          const quarter = today.startOf('year').quarter(4);
          return [quarter, quarter.endOf('year')];
        },
      },
      {
        label: formatMessage({ id: 'button.thisYear' }),
        getValue: () => {
          const today = dayjs();
          const startOfYear = today.startOf('year');
          const endOfYear = today.endOf('year');
          return [startOfYear, endOfYear];
        },
      },
      {
        label: formatMessage({ id: 'button.lastYear' }),
        getValue: () => {
          const today = dayjs();
          const startOfYear = today.startOf('year');
          const endOfYear = today.endOf('year');
          return [
            startOfYear.subtract(1, 'year'),
            endOfYear.subtract(1, 'year'),
          ];
        },
      },
    ],
    [formatMessage]
  );

  return (
    <Box>
      <Typography
        variant="h3"
        fontWeight="bold"
        onClick={() => setShowCalendar(true)}
        ref={anchorEl}
        sx={{ cursor: 'pointer' }}
      >
        <FormattedDateTimeRange
          from={dayjs(start).toDate()}
          to={dayjs(end).toDate()}
          dateStyle="long"
        />
        &nbsp;
        <Icon icon={faCalendarAlt} />
      </Typography>

      <Popper
        open={showCalendar}
        anchorEl={anchorEl.current}
        placement="bottom"
        transition
        style={{ zIndex: 2 }}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
                <Box>
                  <StaticDateRangePicker
                    value={[dayjs(start), dayjs(end)]}
                    shouldDisableDate={shouldDisableDate}
                    displayWeekNumber
                    slots={{
                      shortcuts: CustomRangeShortcuts,
                    }}
                    onAccept={(newValue) => {
                      if (newValue.length === 2) {
                        onChange(newValue[0], newValue[1]);
                        setShowCalendar(false);
                      }
                    }}
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      toolbar: {
                        hidden: true,
                      },
                      actionBar: {
                        hidden: false,
                        actions: ['accept'],
                      },
                    }}
                    calendars={2}
                  />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default SelectPeriodForStatistics;
