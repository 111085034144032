import { StartupErrorBoundary } from '@bas/ui/web/pages';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import '@fontsource/dm-sans';
import '@fontsource/dm-sans/400-italic.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/500-italic.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-sans/700-italic.css';
import '@fontsource/dm-sans/latin.css';
import '@fontsource/dm-sans/latin-400.css';
import '@fontsource/dm-sans/latin-400-italic.css';
import '@fontsource/dm-sans/latin-500.css';
import '@fontsource/dm-sans/latin-500-italic.css';
import '@fontsource/dm-sans/latin-700.css';
import '@fontsource/dm-sans/latin-700-italic.css';
import '@fontsource/work-sans';
import '@fontsource/work-sans/400-italic.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/500-italic.css';
import '@fontsource/work-sans/700.css';
import '@fontsource/work-sans/700-italic.css';
import '@fontsource/work-sans/latin.css';
import '@fontsource/work-sans/latin-400.css';
import '@fontsource/work-sans/latin-400-italic.css';
import '@fontsource/work-sans/latin-500.css';
import '@fontsource/work-sans/latin-500-italic.css';
import '@fontsource/work-sans/latin-700.css';
import '@fontsource/work-sans/latin-700-italic.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { StrictMode, Suspense, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import App from './app/app';
import './styles.css';
import { backofficeTheme } from './backofficeTheme';
import { BootPage } from './BootPage';

if (import.meta.env.MODE !== 'production') {
  document.title = `Local ${document.title}`;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message.includes('refreshing')) {
          return failureCount <= 10;
        }

        if (error instanceof Error && error.message.includes('expired')) {
          return failureCount <= 6;
        }

        return failureCount <= 3;
      },
      retryDelay: (failureCount, error) => {
        if (
          error instanceof Error &&
          (error.message.includes('refreshing') ||
            (error.message.includes('expired') && failureCount <= 3))
        ) {
          if (failureCount <= 1) {
            return 1;
          }

          return Math.min(1000 * failureCount, 30000);
        }

        return Math.min(1000 * 2 ** failureCount, 30000);
      },
      staleTime: 30000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: 'https://7cbab0038d874f019cbbc3a7f186c309@sentry.bas.software/13',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
  release: import.meta.env.VITE_APP_UPDATE,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      enableInp: true,
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.2,
  tracePropagationTargets: ['api.bas.app', 'dev-api.bas.app', 'local.bas.app'],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.3,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '*',
    element: (
      <IntlProvider
        locale="nl"
        messages={{
          'errorFallback.tryAgain': 'Probeer opnieuw',
          'errorFallback.iNeedHelp': 'Hulp nodig?',
          'errorFallback.backToHome': 'Terug naar home',
          'errorFallback.oopsSomethingWentWrong': 'Oeps, er ging iets fout',
          'errorFallback.errorDescription':
            'Er is helaas iets fout gegaan. Excuses hiervoor. Zou je willen beschrijven wat je aan het doen was zodat we de fout kunnen reproduceren?',
          'errorFallback.intakeErrorDescription':
            'Er is helaas iets fout gegaan. Excuses hiervoor. Zou je willen beschrijven wat je aan het doen was zodat we de fout kunnen reproduceren?',
        }}
      >
        <ErrorBoundary fallback={StartupErrorBoundary}>
          <App />
        </ErrorBoundary>
      </IntlProvider>
    ),
  },
]);

root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={backofficeTheme}>
        <ErrorBoundary fallback={StartupErrorBoundary}>
          <IntercomProvider appId="rvvl2w8b" autoBoot>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <Suspense fallback={<BootPage />}>
                <QueryClientProvider client={queryClient}>
                  <RouterProvider router={router} />
                </QueryClientProvider>
              </Suspense>
            </SnackbarProvider>
          </IntercomProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>
);
